import { useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useStore, useTheme } from 'react-admin';
import { darkTheme, lightTheme } from './themes';
import { ThemeTypes } from './ThemeTypes';

function getTheme(themePreference: ThemeTypes, prefersDarkMode: boolean) {
    switch (themePreference) {
        case ThemeTypes.Dark:
            return darkTheme;
        case ThemeTypes.Light:
            return lightTheme;
        default:
            return prefersDarkMode ? darkTheme : lightTheme;
    }
}

function useThemePreference() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [themePreference] = useStore('themePreference', ThemeTypes.System);
    const [, setTheme] = useTheme();

    return useEffect(() => {
        setTheme(getTheme(themePreference, prefersDarkMode));
    }, [prefersDarkMode, themePreference, setTheme]);
}

export default useThemePreference;