import './App.css';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import Dashboard from './pages/Dashboard';
import Layout from './layout/CustomLayout';
import Preferences from './pages/Preferences';
import { RealTime } from './notification/RealTime';
import { Route } from 'react-router';

const App = () => {
  return (
    <Admin
      dashboard={Dashboard}
      disableTelemetry
      layout={Layout}
    >
      <CustomRoutes>
        <Route
          element={<Preferences />}
          path="/preferences" />
      </CustomRoutes>
      <Resource name="Properties" />
      <RealTime />
    </Admin>
  );
};

export default App;