import { useEffect, useState } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

interface FrontendDeployed {
    applicationVersion: string;
}

export function RealTime() {
    const [, setHubConnection] = useState<HubConnection>();
    useEffect(() => {
        const createHubConnection = async () => {

            const hubConnect = new HubConnectionBuilder()
                .withUrl('/api/notification/realtime')
                .withAutomaticReconnect()
                .build();

            hubConnect.on("FrontendDeployed", (message: FrontendDeployed) => {
                console.log(`New application version available: ${message.applicationVersion}`);
            });

            hubConnect.start()
                .then(() => console.log("Connection successful."))
                .catch((err) => console.log("Error while establishing connection ", err));

            setHubConnection(hubConnect);
        };

        createHubConnection();
    }, []);

    return null;
}