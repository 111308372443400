import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CardContent from '@mui/material/CardContent';
import { Title, useStore } from 'react-admin';
import { SelectChangeEvent } from "@mui/material";
import { ThemeTypes } from '../theme/ThemeTypes';

const Preferences = () => {
  const [themeType, setThemeType] = useStore('themePreference', ThemeTypes.System);

  const handleOnChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as ThemeTypes;
    setThemeType(value);
  };

  return (
    <Card>
      <Title title="Preferences" />
      <CardContent>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Theme</InputLabel>
          <Select
            label="Theme"
            onChange={handleOnChange}
            value={themeType}
          >
            <MenuItem value={ThemeTypes.System}>System</MenuItem>
            <MenuItem value={ThemeTypes.Light}>Light</MenuItem>
            <MenuItem value={ThemeTypes.Dark}>Dark</MenuItem>
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default Preferences;