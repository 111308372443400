import { Layout, LayoutProps } from 'react-admin';
import CustomAppBar from './CustomAppBar';
import useThemePreference from '../theme/useThemePreference';

const CustomLayout = (props: LayoutProps) => {
  useThemePreference();

  return <Layout {...props} appBar={CustomAppBar} />;
};

export default CustomLayout;