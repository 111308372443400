import SettingsIcon from '@mui/icons-material/Settings';
import { AppBar, Logout, UserMenu } from 'react-admin';
import { Box, ListItemIcon, ListItemText, MenuItem, Theme, Typography, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';


const ConfigurationMenu = (props: any) => (
  <MenuItem
    component={Link}
    {...props}
    to="/preferences"
  >
    <ListItemIcon>
      <SettingsIcon />
    </ListItemIcon>
    <ListItemText>Preferences</ListItemText>
  </MenuItem>
);

const CustomUserMenu = () => (
  <UserMenu label='User'>
    <ConfigurationMenu />
    <Logout />
  </UserMenu>
);

const CustomAppBar = (props: any) => {
  const isLargeEnough = useMediaQuery<Theme>(theme =>
    theme.breakpoints.up('sm')
  );
  return (
    <AppBar
      {...props}
      color="secondary"
      elevation={1}
      userMenu={<CustomUserMenu />}
    >
      <Typography
        color="inherit"
        id="react-admin-title"
        sx={{
          flex: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        variant="h6"
      />
      {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
    </AppBar>
  );
};

export default CustomAppBar;
